import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, Divider } from '@material-ui/core';
import NavItem from './NavItem';
import useAuth from '../hooks/useAuth';

const NavSection = (props) => {
  const { isMarketWideUser } = useAuth()
  const { items, pathname, title, dividers } = props;

  const renderNavItems = ({ depth = 0, items, pathname, dividers }) => {
    return (
      <List disablePadding key={`nav-section-${depth}`}>
        {items && items.reduce(
          (acc, item) => reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            dividers
          }), []
        )}
      </List>
    )
  };

  const reduceChildRoutes = ({ acc, pathname, item, depth, dividers }) => {
    const key = `${item.title.replace(/\s/g, '')}-${depth}`;
    const exactMatch = item.path ? !!matchPath({
      path: item.path,
      end: true
    }, pathname) : false;

    if (item.children) {
      const partialMatch = item.path ? !!matchPath({
        path: item.path,
        end: false
      }, pathname) : false;

      acc.push(<Fragment key={key + "fragment"}>
        <NavItem
          active={partialMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          noncollapsable={item.noncollapsable}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname
          })}
        </NavItem>
        {dividers && <Divider key={key + "divider"} />}
      </Fragment>
      );
    } else if (isMarketWideUser || item.title !== 'Manage Users') {
      acc.push(<Fragment key={key + "fragment"}>
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
        />
        {dividers && <Divider key={key + "-divider"} />}
      </Fragment>
      );
    }

    return acc;
  };

  return renderNavItems({ items, pathname, dividers });
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
