import { Tooltip, Fab } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import React from 'react';

function ImpersonationOverlay() {
    return (
        <Tooltip title="Exit impersonation">
            <Fab
                color="secondary"
                onClick={() => {
                    // Get user being impersonated
                    const user = JSON.parse(localStorage.getItem('user'));
                    // Restore settings
                    localStorage.setItem('token', localStorage.getItem('token_backup'));
                    localStorage.setItem('settings', localStorage.getItem('settings_backup'));
                    localStorage.setItem('user', localStorage.getItem('user_backup'));
                    // Remove backups
                    localStorage.removeItem('token_backup');
                    localStorage.removeItem('settings_backup');
                    localStorage.removeItem('user_backup');
                    localStorage.removeItem('impersonating');
                    // Go back to admin/user page
                    window.location.href = `/admin/clientmanagement/users/${user.id}`
                }}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    left: 16,
                    zIndex: 9999
                }}
            >
                <PersonIcon />
            </Fab>
        </Tooltip>
    );
}

export default ImpersonationOverlay;
