import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'nprogress/nprogress.css';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { APIProvider } from './contexts/APIContext'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config';
import BackendAPIClient from './lib/backendApi'
import { getSiteCode, nodeURL } from './constants';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ErrorScreen } from './ErrorScreen'

Bugsnag.start({
  apiKey: '6593921330b64b304feaaf15740149cd',
  plugins: [new BugsnagPluginReact()],
  appVersion: `${process.env.REACT_APP_BUILD}`,
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['production', 'staging'],
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const msalInstance = new PublicClientApplication(msalConfig);
const backendApiInstance = new BackendAPIClient(nodeURL, localStorage.getItem('token'), getSiteCode())

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <HelmetProvider>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <BrowserRouter>
                  <MsalProvider instance={msalInstance}>
                    <AuthProvider backendApi={backendApiInstance}>
                      <APIProvider instance={backendApiInstance}>
                        <App />
                      </APIProvider>
                    </AuthProvider>
                  </MsalProvider>
                </BrowserRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </StrictMode>, document.getElementById('root')
);
