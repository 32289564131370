import { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Avatar, Box, Button, IconButton, Divider, Drawer, Link, Tooltip, Typography, makeStyles } from '@material-ui/core'
import useAuth from '../../hooks/useAuth'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Scrollbar from '../Scrollbar'


import { IconContext } from 'react-icons'
import { BsMusicNoteBeamed, BsFillMicFill, BsHeadphones, BsArrowRightCircleFill } from 'react-icons/bs'
import { BiArrowFromLeft } from 'react-icons/bi'
import { FaUserFriends } from 'react-icons/fa'
import { HiDocumentReport } from 'react-icons/hi'
import { MdHelp } from 'react-icons/md'
import { IoSettingsSharp } from 'react-icons/io5'
import { AiFillHome } from 'react-icons/ai'



const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: '#EFEDED'
  },
}))



const AdminMiniSidebar = (props) => {
  const classes = useStyles();
  const { onMobileClose, openMobile, onOpenSidebar } = props
  const location = useLocation()
  const navigate = useNavigate()
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const [isMiniNav, setIsMiniNav] = useState(false)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box
      className={classes.sidebar}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        height: '100%',
      }}
      {...props}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ pt: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Tooltip title="Spots Management" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/spotsmanagement"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><BsMusicNoteBeamed /> </IconContext.Provider>
            </Button>
          </Tooltip>
          <Tooltip title="Client Management" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/clientmanagement"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><FaUserFriends /> </IconContext.Provider>
            </Button>
          </Tooltip>
          <Tooltip title="Producers" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/producer-admin"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><BsFillMicFill /> </IconContext.Provider>
            </Button>
          </Tooltip>
          <Tooltip title="Concierge Settings" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/concierge-settings"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><BsHeadphones /> </IconContext.Provider>
            </Button>
          </Tooltip>
          <Tooltip title="Site Settings" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/site-settings"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><IoSettingsSharp /> </IconContext.Provider>
            </Button>
          </Tooltip>
          <Tooltip title="Reports/Tracking" placement='right'>
            <Button
              component={RouterLink}
              to="/admin/reports"
              sx={{ p: 2 }}
            >
              <IconContext.Provider value={{ color: '#EDA418', size: '30px' }}><HiDocumentReport /> </IconContext.Provider>
            </Button>
          </Tooltip>

        </Box>
      </Scrollbar>
      <Box sx={{ pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Tooltip title="Home" placement='right'>
          <Button
            component={RouterLink}
            to="/admin"
            sx={{ p: 2 }}
          >
            <IconContext.Provider value={{ color: '#EDA418', size: '32px' }}><AiFillHome /> </IconContext.Provider>
          </Button>
        </Tooltip>
        <Tooltip title="Help" placement='right'>
          <Button
            component={RouterLink}
            to="/admin/help"
            sx={{ p: 2 }}
          >
            <IconContext.Provider value={{ color: '#EDA418', size: '32px' }}><MdHelp /> </IconContext.Provider>
          </Button>
        </Tooltip>
        <Tooltip title="Expand Menu" placement='right'>
          <Button
            sx={{ p: 2 }}
            onClick={onOpenSidebar}
          >
            <IconContext.Provider value={{ color: '#EDA418', size: '34px' }}><BiArrowFromLeft /> </IconContext.Provider>
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            height: 'calc(100% - 65px) !important',
            top: '65px !Important',
            width: 75,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          height: 'calc(100% - 65px) !important',
          top: '65px !Important',
          width: 70,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  )
}

AdminMiniSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
}

export default AdminMiniSidebar
