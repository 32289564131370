import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Market } from '../lib/backendApi/types/market'
import { THEMES } from '../constants';

/**
 * @type {{
 *    compact: boolean, 
 *    direction: 'ltr'|'rtl', 
 *    responsiveFontSizes: boolean, 
 *    roundedCorners: boolean, 
 *    theme: string, 
 *    market: Market | undefined,
 *    cumulusMarkets: Market[] | undefined,
 * }}
 */
const initialSettings = {
  compact: true,
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: getTheme(),
  market: undefined,
  cumulusMarkets: undefined,
  roundedCorners: false
};

function getTheme() {
  const host = window.location.hostname;
  if (host.endsWith('cumuluscreativeconcierge.com')) {
    return THEMES.CCC;
  }

  if (host.endsWith('audacyflash.com')) {
    return THEMES.AUDACYFLASH;
  }

  if (host.endsWith('specbyte.com')) {
    return THEMES.SPECBYTE;
  }
  if (host.endsWith('cashbycreative.com')) {
    return THEMES.CASHBYCREATIVE;
  }
  return THEMES.SPECBYTE;
}

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = initialSettings
    }
  } catch (err) {
    console.error(err);
    return initialSettings
  }

  return settings;
};

export const storeSettings = (settings) => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: () => { },
  setMarket: () => { }
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [settings, setSettings] = useState(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      saveSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  const setMarket = (market) => {
    setSettings({ ...settings, market: market })
    storeSettings({ ...settings, market: market })
  }

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        setMarket
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
