import { useState } from 'react'
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast'
import { AppBar, Autocomplete, Box, Button, Chip, Divider, IconButton, Link, TextField, Toolbar, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { useTheme } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import useAuth from '../../hooks/useAuth';
import AdminAccountPopover from './AdminAccountPopover';

const useStyles = makeStyles((theme) => ({
  siteSelect: {
    backgroundColor: '#fff',
    borderRadius: 5
  }
}))

const sites = [
  {
    title: 'Cash by Creative',
    value: 'CashByCreative'
  },
  {
    title: 'CCC',
    value: 'ccc'
  },
  {
    title: 'SPECbyte',
    value: 'SPECbyte'
  },
]

const AdminNavbar = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { onSidebarMobileOpen } = props;
  const { accounts } = useMsal()
  const { logout } = useAuth()
  const navigate = useNavigate()
  const { isAuthenticated } = useIsAuthenticated()
  const name = accounts[0] && accounts[0].name
  const [selectedSite, setSelectedSite] = useState('producer')

  const handleLogout = async () => {
    try {
      const response = await logout()
      if (response) {
        navigate('/')
      }
    } catch (err) {
      console.error('Error in AdminNavbar handleLogout', err)
      toast.error('Unable to logout')
    }
  }

  const handleChangeSite = (event) => {
    setSelectedSite(event.target.value)
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: '#3E3D3D',
        color: theme.palette.navigation?.fontColor
      }}
    >
      <Toolbar sx={{ minHeight: 62, pt: 1, pb: .5 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }}>
          {accounts.length > 0 ? (
            <Autocomplete
              getOptionLabel={(option) => option.title}
              options={sites}
              autoComplete="on"
              size='small'
              renderInput={(params) => (
                <TextField
                  className={classes.siteSelect}
                  name="producer"
                  variant="outlined"
                  value={selectedSite}
                  onChange={(e) => handleChangeSite(e.target.value)}
                  {...params}
                  sx={{ ml: 4, backgroundColor: 'background.paper' }}
                />
              )}
            />
          ) : null}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          {accounts.length > 0 ? (
            <>
              <Link
                // color="textSecondary"
                component={RouterLink}
                to="/customrequest"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor }}
              >
                Spots Management
              </Link>
              <Link
                // color="textSecondary"
                component={RouterLink}
                to="/concierge"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor }}
              >
                Clients
              </Link>
              <Link
                // color="textSecondary"
                component={RouterLink}
                to="/account/myspecs"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor }}
              >
                Account Settings
              </Link>
            </>
          ) : (
            <>
              <Link
                // color="textSecondary"
                component={RouterLink}
                to="/"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor }}
              >
                Home
              </Link>
              <Link
                // color="textSecondary"
                component={RouterLink}
                to="/help"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor }}
              >
                Need Help?
              </Link>
            </>
          )

          }
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          {accounts.length > 0 ? (
            <>
              <AdminAccountPopover />
              <Button
                component="a"
                onClick={handleLogout}
                size="medium"
                variant="contained"
                sx={{ ml: 2, backgroundColor: theme.palette.primary.main }}
              >
                Log Out
              </Button>

            </>
          ) : (
            <Button
              component="a"
              onClick={handleLogout}
              size="medium"
              variant="contained"
              sx={{ ml: 2, backgroundColor: theme.palette.primary.main }}
            >
              Log Out
            </Button>
          )}
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

AdminNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default AdminNavbar;
