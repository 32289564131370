import { useContext } from 'react';
import APIContext from '../contexts/APIContext';
import BackendAPIClient from '../lib/backendApi';

/**
 * 
 * @returns { {api: BackendAPIClient} } instance of backend API client
 */
const useAPI = () => useContext(APIContext);

export default useAPI;
