import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Link } from '@material-ui/core';
import { LoginJWT } from '../../components/authentication/login';

import useSettings from '../../hooks/useSettings';
import { useTheme } from '@material-ui/core';
import gtm from '../../lib/gtm';
import SpecByteLogo from '../../components/SpecByteLogo'
import CCCLogo from '../../components/CCCLogo'
import CashByCreativeLogo from '../../components/CashByCreativeLogo'
import CreativeLoveLogo from '../../components/CreativeLoveLogo';
import AudacyFlashLogo from '../../components/AudacyFlashLogo';
import YamanairLogo from '../../components/YamanairLogo';
import LoginAzure from '../../components/authentication/login/LoginAzure';

const Login = () => {
  const { settings } = useSettings();
  const theme = useTheme()
  const [useAAD, setUseAAD] = useState(false)

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    if (settings.theme === 'CCC') {
      setUseAAD(true)
    }
  }, [settings.theme])

  return (
    <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Container maxWidth="sm" sx={{ py: '80px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>
          <RouterLink to="/" >
            {(() => {
              switch (theme.name) {
                case 'SPECbyte':
                  return <SpecByteLogo width="320px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
                case 'CCC':
                  {/* return null; */ }
                  return <CCCLogo width="320px" sx={{ display: { md: 'inline', xs: 'none' }, }} variant="dark" />;
                case 'CashByCreative':
                  return <CashByCreativeLogo width="300px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
                case 'CreativeLove':
                  return <CreativeLoveLogo width="240px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
                case 'AudacyFlash':
                  return <AudacyFlashLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
                case 'Admin':
                  return <YamanairLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
                default:
                  return null
              }
            })()}
          </RouterLink>
        </Box>
        <Card>
          <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
            <Box sx={{ flexGrow: 1, mt: 3, mb: 3 }}>
              {useAAD
                ? <LoginAzure showLocalAuth={() => setUseAAD(false)} />
                : <LoginJWT showADAuth={() => setUseAAD(true)} />
              }
            </Box>
            {!useAAD && theme.name !== 'CCC' && <Link href="/authentication/password-recovery" color="primary" underline="hover">Forget your password?</Link>}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Login;
