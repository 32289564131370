import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, makeStyles, Paper, TextField, Button, FormControlLabel, Checkbox, FormGroup, FormHelperText } from '@material-ui/core'
import gtm from '../../../lib/gtm';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import useAPI from '../../../hooks/useAPI'

import useSettings from '../../../hooks/useSettings';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import { LoadingButton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  stepperContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  buttonsContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
  },
  backButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}))

const DemandView = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { api } = useAPI()
  const { settings } = useSettings()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      formats: [],
      title: '',
      description: ''
    },
    validationSchema: Yup.object().shape({
        formats: Yup.array().min(1, 'Please select at least one format'),
        title: Yup.string().max(255).required('Title is required'),
        description: Yup.string().max(1000).required('Description is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      const requestData = {
        format: values.formats.join(','),
        comment: `${values.title} - ${values.description}`,
      }
      const response = await api.OnDemand.create({ format: values.formats.join(','), comment: values.description, category: values.title })
      if(response.error) {
        toast.error('Error creating request - if this persists, please contact support', 10000)
        console.error("Failed to submit request", response)
        return
      }
      // If there is an error getting here, keep the button disabled...
      setLoading(false)
      setSubmitted(true)
    }
  })

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  if(!user) {
    navigate('/authentication/login')
  }

  return (
    <>
      <Helmet>
        <title>Missing Category Request</title>
    </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '95%',
          py: { md: 4, sm: 2, xs: 2 },
          px: { md: 14, sm: 4, xs: 2 },
          pb: { md: 10, sm: 8, xs: 6 }
        }}
      >
        {!submitted
        ? <>
        <Grid container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <Box className={classes.pageHeader}>
              <Typography variant="h5">
                Missing Category Request
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.form}>
            <form onSubmit={formik.handleSubmit}>
                <Paper sx={{p: 2}}>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={12} xs={12}>
                            <Typography variant="h6">
                                Category name
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name={'title'}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                error={formik.errors.title ? true : false}
                                helperText={formik.errors.title ? formik.errors.title : ' '}
                            />
                        </Grid>
                        <Grid item md={8} xs={12}></Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6">
                                Category Description / Details
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                name={'description'}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.errors.description ? true : false}
                                helperText={formik.errors.description ? formik.errors.description : ' '}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Choose Formats</Typography>
                            <FormGroup sx={{ border: Boolean(formik.errors?.formats) ? '1px solid red' : 'none', borderRadius: 1, px: 2 }}>
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={formik.values.formats.includes('ROCK/CHR')} 
                                        onChange={(e) => {
                                            if(e.target.checked && formik.values.formats.includes('ROCK/CHR')) return
                                            if(e.target.checked) {
                                                formik.setFieldValue('formats', [...formik.values.formats, 'ROCK/CHR'])
                                            } else {
                                                formik.setFieldValue('formats', formik.values.formats.filter(f => f !== 'ROCK/CHR'))
                                            }
                                        }}  
                                        name="ROCK/CHR" 
                                    />
                                } label="ROCK/CHR" />
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={formik.values.formats.includes('AC/COUNTRY')} 
                                        onChange={(e) => {
                                            if(e.target.checked && formik.values.formats.includes('AC/COUNTRY')) return
                                            if(e.target.checked) {
                                                formik.setFieldValue('formats', [...formik.values.formats, 'AC/COUNTRY'])
                                            } else {
                                                formik.setFieldValue('formats', formik.values.formats.filter(f => f !== 'AC/COUNTRY'))
                                            }
                                        }}  
                                        name="AC/COUNTRY" 
                                    />
                                } label="AC/COUNTRY" />
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={formik.values.formats.includes('URBAN')} 
                                        onChange={(e) => {
                                            if(e.target.checked && formik.values.formats.includes('URBAN')) return
                                            if(e.target.checked) {
                                                formik.setFieldValue('formats', [...formik.values.formats, 'URBAN'])
                                            } else {
                                                formik.setFieldValue('formats', formik.values.formats.filter(f => f !== 'URBAN'))
                                            }
                                        }}  
                                        name="URBAN" 
                                    />
                                } label="URBAN" />
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={formik.values.formats.includes('SPOKEN WORD')} 
                                        onChange={(e) => {
                                            if(e.target.checked && formik.values.formats.includes('SPOKEN WORD')) return
                                            if(e.target.checked) {
                                                formik.setFieldValue('formats', [...formik.values.formats, 'SPOKEN WORD'])
                                            } else {
                                                formik.setFieldValue('formats', formik.values.formats.filter(f => f !== 'SPOKEN WORD'))
                                            }
                                        }}  
                                        name="SPOKEN WORD" 
                                    />
                                } label="SPOKEN WORD" />
                                <FormControlLabel control={
                                    <Checkbox 
                                        checked={formik.values.formats.includes('SPANISH')} 
                                        onChange={(e) => {
                                            if(e.target.checked && formik.values.formats.includes('SPANISH')) return
                                            if(e.target.checked) {
                                                formik.setFieldValue('formats', [...formik.values.formats, 'SPANISH'])
                                            } else {
                                                formik.setFieldValue('formats', formik.values.formats.filter(f => f !== 'SPANISH'))
                                            }
                                        }}  
                                        name="SPANISH" 
                                    />
                                } label="SPANISH" />
                            </FormGroup>
                            <FormHelperText error>{formik.errors.formats ? formik.errors.formats : ' '}</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                sx={{px: 5, mr: 2}}
                                onClick={() => navigate('/concierge')}
                                disabled={loading}
                            >
                                Back
                            </Button>
                            <LoadingButton 
                                variant="contained" 
                                color="success" 
                                sx={{px: 5}}
                                loading={loading}
                                type="submit"
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </Box>
        </>
        : <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
            <Typography variant="h4" sx={{textAlign: 'center', mt: 5}}>
                Thank you for your request
            </Typography>
            <Typography variant="body2" sx={{textAlign: 'center', mt: 2}}>
                Please allow up to 3 business days for completion. If submitted after 2pm EST this will be considered a next day request.
                A link will be sent directly to you once it is ready for customization.
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
                <Button variant="contained" href="/concierge" sx={{width: 250}}>
                    Back to Concierge
                </Button>
            </Box>
        </Box>
       }
      </Box>
    </>
  )
};


export default DemandView;
