import { Box, Container, Divider, Grid, Link, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

const getSiteName = (theme) => {
  switch (theme.name) {
    case 'SPECbyte':
      return 'SPECbyte';
    case 'CCC':
      return 'Cumulus Creative Concierge';
    case 'CashByCreative':
      return 'Cash By Creative'
    default:
      return ""
  }
}



const FrontendFooter = () => {
  const theme = useTheme()

  const links = [
    {
      title: 'About Us',
      href: '/about',
    },
    {
      title: 'Terms',
      href: '/terms',
    }
  ]

  if (theme.oldSiteUrl) links.push(
    {
      title: `Back to old ${theme.name}`,
      href: `${theme.oldSiteUrl}`,
    })

  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.navigation?.background || theme.palette.background.default,
        color: theme.palette.navigation?.fontColor,
        pb: 2,
        pt: { md: 2, xs: 2 },
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={8} md={6} sm={6} xs={12} sx={{ flexDirection: 'column', order: { md: 1, xs: 4 } }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography color="inherit" sx={{ mt: 1 }} variant="caption" >
                {/* {getSiteName(theme)} is a federally registered trademark of <Link href="https://www.yamanair.com" color="inherit">Yamanair Creative</Link>. 
              All Rights Reserved. Copyright 2007-2022. Yamanair Creative is a division of <Link href="https://www.compassmedianetworks.com/" color="inherit">Compass Media Networks</Link>. */}
                © {getCurrentYear()} A Service of <a href='http://www.yamanair.com/'>Yamanair Creative</a>, LLC. All rights reserved. <a href='/terms'>Terms of Use</a>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} sx={{ display: 'flex', flexDirection: 'row', order: { md: 1, xs: 4 } }}>
            {
              links.map(link => (
                <Box key={link.title} sx={{ display: "flex" }}>
                  <Link href={link.href} sx={{ display: 'flex', alignItems: 'center' }} color="inherit">
                    {link.title}
                  </Link>
                  <Divider orientation="vertical" sx={{ height: 32, mx: 2 }} />
                </Box>
              ))
            }
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
};

export default FrontendFooter;
