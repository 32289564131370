import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const NewUserGuard = ({ children }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
};

NewUserGuard.propTypes = {
    children: PropTypes.node
};

export default NewUserGuard;
