import React, { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import BackendAPIClient from "../lib/backendApi";

const APIContext = createContext({
  instance: undefined
});

/**
 * @param {*} props 
 * @returns {React.Context<{api: BackendAPIClient}>}
 */
export const APIProvider = (props) => {
  const { instance, children } = props;
  const [value] = useState(instance);

  return (
    <APIContext.Provider value={{ api: value }} >
      {children}
    </APIContext.Provider>
  );
};

APIProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const APIConsumer = APIContext.Consumer;

export default APIContext;
