import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavSection from '../../NavSection';

const sections = [
  {
    title: 'My Account',
    items: [
      // {
      //   title: 'Pending Approval',
      //   path: '/audacyflash/account/pendingapproval'
      // },
      {
        title: 'My Specs',
        path: '/audacyflash/account/specs'
      },
      {
        title: 'My Customs',
        path: '/audacyflash/account/customs',
        icon: ''
      },
      {
        title: 'My Digitals',
        path: '/audacyflash/account/digitals',
        icon: ''
      },
      // {
      //   title: 'External Sales',
      //   path: '/audacyflash/account/externalsales',
      //   icon: ''
      // },
      {
        title: 'Account Info',
        path: '/audacyflash/account/accountinfo',
        icon: ''
      },
    ]
  }
]

const AudacySidebar = (props) => {
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, width: 300 }}>
      <Box>
        <Box sx={{ justifyContent: 'center' }}>
          <Typography variant="h3" >My Account</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              dividers
              {...section}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );

  if (lgUp) {
    return content
  }

  return (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: { backgroundColor: 'background.default', width: 280 }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

AudacySidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default AudacySidebar;
