// DynamicFavicon.js
import { useEffect } from 'react';

const DynamicFavicon = ({ theme }) => {
    useEffect(() => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = theme.favicon

        document.getElementsByTagName('head')[0].appendChild(link);
    }, [theme]);

    return null;
};

export default DynamicFavicon;
