import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import NavSection from '../../NavSection';
import { FilterBuilder, WhereBuilder } from '@loopback/filter';
import useAPI from '../../../hooks/useAPI';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';


const AccountSidebar = (props) => {
  const { settings } = useSettings();
  const location = useLocation();
  const theme = useTheme();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { isSiteAdmin, isCompanyWideUser, isMarketWideUser, user } = useAuth();
  const { api } = useAPI()
  const [specialsCount, setSpecialsCount] = useState(0)
  const [sectionItems, setSectionItems] = useState([])

  const sections = [
    {
      site: 'sb',
      items: [
        {
          title: 'My Account',
          items: [
            {
              title: 'My Specs',
              path: '/account/myspecs'
            },
            {
              title: 'Concierge',
              path: '/account/concierge',
              icon: ''
            },
            {
              title: 'Monitor',
              path: '/account/monitor',
              icon: ''
            },
            {
              title: 'Banners',
              path: '/account/banners',
              icon: ''
            },
            {
              title: 'PreRolls',
              path: '/account/prerolls',
              icon: ''
            },
            {
              title: 'Specials',
              path: '/account/specials',
              icon: ''
            },
            {
              title: 'Demands',
              path: '/account/demands',
              icon: ''
            },
            {
              title: 'Favorites',
              path: '/account/favorites',
              icon: ''
            },
            {
              title: 'Manage Users',
              path: '/account/manage-users',
              icon: ''
            },
            {
              title: 'Account Info',
              path: '/account/accountinfo',
              icon: ''
            },
            // {
            //   title: 'Reports',
            //   path: '/account/reports',
            //   icon: ''
            // },
          ]
        }
      ]
    },
    {
      site: 'ccc',
      items: [
        {
          title: 'My Account',
          items: [
            {
              title: 'My Specs',
              path: '/account/myspecs',
              noncollapsable: true,
              children: [
                {
                  title: 'My Audio Specs',
                  path: '/account/myspecs/audio'
                },
                {
                  title: 'My Banner Specs',
                  path: '/account/myspecs/digital'
                },
              ]
            },


            {
              title: 'Full Productions',
              path: '/account/concierge/fullproductions'
            },
            {
              title: 'Dry Voices',
              path: '/account/concierge/dryvoice'
            },
            {
              title: 'Campaign - Audio',
              path: '/account/concierge/audio'
            },
            {
              title: 'Campaign - Digital',
              path: '/account/concierge/digital'
            },
            {
              title: 'Jingles',
              path: '/account/concierge/jingle'
            },
            {
              title: 'My Advertisers',
              path: '/account/advertisers',
              icon: ''
            },
            {
              title: 'Old CCC Campaigns',
              path: '/account/specials',
              icon: ''
            },
            {
              title: 'Monitor',
              path: '/account/monitor',
              icon: ''
            },
            {
              title: 'Favorites',
              path: '/account/favorites',
              icon: ''
            },
            {
              title: 'Manage Users',
              path: '/account/manage-users',
              icon: ''
            },
            {
              title: 'Account Info',
              path: '/account/accountinfo',
              icon: ''
            },
            {
              title: 'Reports',
              path: '/account/reports',
              icon: ''
            },
          ]
        }
      ]
    }
  ]

  const getCount = async () => {
    try {
      const w = new WhereBuilder()
      w.and({ marketId: settings.market.id })
      if (!isMarketWideUser) w.and({ createdBy: user.email });

      const filter = new FilterBuilder();
      filter.where(w.where);

      const response = await api.CustomSpotRequest.count(filter.build());

      if (response.error) {
        throw response.error;
      }
      setSpecialsCount(response.count)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getCount();
  }, [])

  useEffect(() => {
    getSection();
  }, [specialsCount])

  const getSection = () => {
    const site = theme.site
    const section = sections.find(s => s.site === site)

    if (!section) return

    const items = section.items.map(s => {
      // If section contains link to specials, and user has no specials, remove the link
      if (s.items.some(i => i.path === "/account/specials") && specialsCount === 0) {
        s.items = s.items.filter(i => i.path !== "/account/specials")
      }
      // If section contains Monitor link, and user is not site admin, company wide user, or market wide user, remove the link
      if (s.items.some(i => i.path === "/account/monitor") && !(isSiteAdmin || isCompanyWideUser || isMarketWideUser)) {
        s.items = s.items.filter(i => i.path !== "/account/monitor")
      }
      // If section contains Reports link, and user is not site admin or company wide user, remove the link
      if (s.items.some(i => i.path === "/account/reports") && !(isSiteAdmin || isCompanyWideUser)) {
        s.items = s.items.filter(i => i.path !== "/account/reports")
      }

      return s
    })

    setSectionItems(items)
  }

  const content = (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
      <Box sx={{ justifyContent: 'center' }}>
        <Typography variant="h3" >My Account</Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        {
          sectionItems.map((section, idx) => {
            return <NavSection
              key={section.title + "-" + idx}
              pathname={location.pathname}
              dividers
              {...section}
            />
          })
        }
      </Box>
    </Box>
  );

  if (lgUp) {
    return content
  }

  return (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: { backgroundColor: 'background.default', width: 280 }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

AccountSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default AccountSidebar;
