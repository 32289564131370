import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';
import useSettings from '../../../hooks/useSettings';

const LoginJWT = ({ showADAuth }) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const { settings } = useSettings();
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        password: Yup.string().max(255).required('Password is required')
      })}
      onSubmit={ async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await login(values.email, values.password);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err.response.data);
            if (mounted.current) {
              setStatus({ success: false });
              const res = err.response.data;
              console.log("res", res)
              if(res.details) {
                setErrors({ submit: res.error.details[0].message });
              } else {
                setErrors({ submit: res.error.message });
              }
              setSubmitting(false);
            }
          }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-evenly' }}>
            <Button
              color="primary"
              variant="outlined"
              sx={{ px: 5, py: 1 }}
              onClick={() => navigate('/')}
              style={{ borderRadius: 30 }}
            >
              Back
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ px: 5, py: 1, ml: 2 }}
              style={{ borderRadius: 30, }}
            >
              Log In
            </Button>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            {settings.theme === 'CCC'
              ? <Button onClick={showADAuth}>Cumulus Login</Button>
              : null
            }
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
