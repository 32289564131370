import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: 'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 700,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 500,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

export const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        // main: '#28739D',
        main: '#005CB9',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.SPECBYTE]: {
    name: 'SPECbyte',
    site: 'sb',
    supportUrl: 'https://support.specbyte.com',
    heroText: 'SPECbyte is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.',
    heroImg: "/static/frontend/fallback.png",
    heroVid: "/static/frontend/Sb V2.0 bckgr.m4v",
    favicon: "favicon2.ico",
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      navigation: {
        background: '#ffffff',
        fontColor: '#6b778c'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#28739D',
        accent: '#28739D',
        dark: '#003462',
        color1: '#28739D',
        color2: '#28739D',
        activeNav: 'rgb(40 114 157 / 25%)',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        contrast: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      default: {
        contrastText: '#ffffff',
        main: '#A7A7A7'
      }
    },
    shadows: lightShadows
  },
  [THEMES.CASHBYCREATIVE]: {
    name: 'CashByCreative',
    site: 'cbc',
    oldSiteUrl: 'https://old.cashbycreative.com',
    supportUrl: 'https://support.cashbycreative.com',
    heroText: 'Cash By Creative is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.',
    heroImg: "/static/frontend/CashByCreative_Hero_Image.png",
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      navigation: {
        background: '#3E3D3D',
        fontColor: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#A1BF44',
        accent: '#D5E996',
        color1: '#A1BF44',
        color2: '#D5E996',
        activeNav: 'rgb(161 191 68 / 20%)',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        contrast: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      default: {
        contrastText: '#ffffff',
        main: '#A7A7A7'
      }
    },
    shadows: lightShadows
  },
  [THEMES.CCC]: {
    name: 'CCC',
    site: 'ccc',
    supportUrl: 'https://support.cumuluscreativeconcierge.com',
    heroText: 'Cumulus Creative Concierge is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.',
    heroImg: "/static/frontend/CCC_Hero_Image.png",
    favicon: "ccc.ico",
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      navigation: {
        background: '#0063A8',
        fontColor: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#0097CD',
        accent: '#0063A8',
        color1: '#0097CD',
        color2: '#0063A8',
        activeNav: '#e0ecf5',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        contrast: '#ffffff',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      default: {
        contrastText: '#ffffff',
        main: '#A7A7A7'
      }
    },
    shadows: lightShadows
  },
  [THEMES.CREATIVELOVE]: {
    name: 'CreativeLove',
    whiteLabel: true,
    supportUrl: 'https://support.creativelove.com',
    supportPhone: '202-888-7776',
    supportEmail: 'creativelove@yamanair.com',
    heroText: 'Creative Love is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.',
    heroImg: "/static/frontend/CreativeLove_Hero_Image.png",
    favicon: "creative.ico",
    logo: `/static/CreativeLoveLogo.png`,
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      navigation: {
        background: '#ffffff',
        fontColor: '#C6002B'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#C6002B',
        accent: '#9621BF',
        dark: '#003462',
        color1: '#C6002B',
        color2: '#9621BF',
        activeNav: 'rgb(198 0 43 / 15%)',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        contrast: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      default: {
        contrastText: '#ffffff',
        main: '#A7A7A7'
      }
    },
    shadows: lightShadows
  },
  [THEMES.AUDACYFLASH]: {
    name: 'AudacyFlash',
    whiteLabel: true,
    supportUrl: 'https://support.audacyflash.com',
    supportPhone: '202-888-7776',
    supportEmail: 'audacyflash@yamanair.com',
    heroText: 'Audacy Flash is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.',
    heroImg: "/static/frontend/AudacyFlash_Hero_Image.png",
    favicon: "audacy.ico",
    logo: `/static/AudacyFlashLogo.png`,
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff'
      },
      navigation: {
        background: '#ffffff',
        fontColor: '#4867CB'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#F26722',
        accent: '#4867CB',
        dark: '#003462',
        color1: '#F26722',
        color2: '#4867CB',
        activeNav: 'rgb(199 241 84 / 15%)',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        contrast: '#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      default: {
        contrastText: '#ffffff',
        main: '#A7A7A7'
      }
    },
    shadows: lightShadows
  },
  [THEMES.ADMIN]: {
    name: 'Admin',
    heroText: 'Yamanair Admin Dashboard',
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: '#1D448F',
            color: '#ffffff',
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }
        }
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            color: '#FFF',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '&:hover': {
              color: '#FFF',
            },
            '&:active': {
              color: '#FFF',
            },
            '&.Mui-active': {
              color: '#FFF',
              '&:hover': {
                color: '#FFF',
              },
              '&:active': {
                color: '#FFF',
              },
            },
            '& > svg': {
              color: '#FFF !important',
            }
          },
          icon: {
            color: '#FFF',
            '&:hover': {
              color: '#FFF',
            },
            '&:active': {
              color: '#FFF',
            },
          },
          iconDirectionDesc: {
            color: '#FFF',
          },
          iconDirectionAsc: {
            color: '#FFF',
          }
        }
      },
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f8f8f8',
        paper: '#fff'
      },
      navigation: {
        background: '#3E3D3D',
        fontColor: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#EDA418',
        accent: '#5091F2',
        color1: '#51D16D',
        color2: '#1D448F'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#000',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#CF2A2A'
      }
    },
    shadows: lightShadows
  },
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.ADMIN];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      }
    })
  }, {
    direction: config.direction
  }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
