import { Box, Button, Typography } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { uniqueId } from 'lodash';

const LoginAzure = ({ showLocalAuth }) => {
  const { user } = useAuth();
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user])

  const beginLogin = () => {
    const clientId = process.env.REACT_APP_AZURE_CLIENT_ID
    const redirectUri = window.location.origin + "/auth/azure/redirect";
    const scope = 'openid profile';
    const state = uniqueId(); // TODO: Generate, locally store, and validate this value
    localStorage.setItem('azure_state', state);
    const url = encodeURI(`https://login.microsoftonline.com/e5d6709f-becf-4b05-8cee-37f5a62617c4/oauth2/v2.0/authorize\
    ?client_id=${clientId}\
    &response_type=id_token\
    &redirect_uri=${redirectUri}\
    &response_mode=fragment\
    &scope=${scope}\
    &state=${state}\
    &nonce=${state}`);

    window.location.href = url;
  }

  return (
    <Box>
      <Button
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => beginLogin()}
      >
        Log In Using Cumulus Account
      </Button>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mt: 1 }}>
        <Typography variant='caption' textAlign='center'>
          For login issues, contact your Help Desk at it.support@cumulus.com.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            color="primary"
            sx={{ mt: 2, alignSelf: 'center' }}
            disabled
          >
            Admin Login
          </Button>
          <Button
            color="primary"
            sx={{ mt: 2, alignSelf: 'center' }}
            onClick={showLocalAuth}
          >
            Local Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginAzure;
