import { Typography, Button, Link, Box } from "@material-ui/core";
import { BiError } from "react-icons/bi";

/** 
* @param {{ error: Error, info: React.ErrorInfo, clearError: () => void }} props
 * @returns 
 */
export const ErrorScreen = ({
    error,
    info,
    clearError
}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <Box sx={{border: '1px solid #FEFEFE', background: '#FAFAFA', padding: 4, borderRadius: 0.5, textAlign: 'center'}}>
                <BiError size={64} color="red" />
                <Typography className="mb-16" variant="h3">
                    Something went wrong!
                </Typography>
                <Typography className="mb-16" variant="subtitle1">
                    If this issue persists, please contact support.
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.reload()}
                        sx={{marginRight: 2}}
                    >
                        Reload page
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        component={Link}
                        to="/"
                    >
                        Go To Homepage
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}