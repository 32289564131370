import { useState } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled, createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import AdminNavbar from './AdminNavbar';
import AdminSidebar from './AdminSidebar';
import AdminMiniSidebar from './AdminMiniSidebar';


const sites = [
  {
    name: 'SPECbyte',
    color1: '#28739D',
    color2: '#C7F154',
    navBgColor: '#fff',
    navFontColor: '#6b778c',
    heroText: 'SPECbyte is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.'
  },
  {
    name: 'CashByCreative',
    color1: '#A1BF44',
    color2: '#D5E996',
    navBgColor: '#3E3D3D',
    navFontColor: '#fff',
    heroText: 'CashByCreative is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.'
  },
  {
    name: 'CCC',
    color1: '#0097CD',
    color2: '#0063A8',
    navBgColor: '#0063A8',
    navFontColor: '#fff',
    heroText: 'CCC is Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis sed gravida ullamcorper.'
  },
]


const AdminLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const AdminLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '40px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px'
  }
}));

const AdminLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AdminLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  // overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});


const AdminLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [isMiniBar, setIsMiniBar] = useState(false);
  const theme = useTheme()

  const handleCloseSidebar = () => {
    setIsMiniBar(true)
  }

  const handleOpenSidebar = () => {
    setIsMiniBar(false)
  }

  return (
    <AdminLayoutRoot>
      <AdminNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      {isMiniBar ?
        <AdminMiniSidebar
          onOpenSidebar={handleOpenSidebar}
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        /> :
        <AdminSidebar
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
          onCloseSidebar={handleCloseSidebar}
        />
      }
      <AdminLayoutWrapper sx={{
        [theme.breakpoints.up('lg')]: {
          paddingLeft: isMiniBar ? '80px' : '280px'
        }
      }}>
        <AdminLayoutContainer>
          <AdminLayoutContent>
            <Outlet />
          </AdminLayoutContent>
        </AdminLayoutContainer>
      </AdminLayoutWrapper>
    </AdminLayoutRoot>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node
};

export default AdminLayout;
