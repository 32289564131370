import { experimentalStyled } from '@material-ui/core/styles';


const CCCLogo = (props) => {
  if (props.variant == 'dark') {
    return <img src="/static/CCCLogoDark.png" width={props.width} />
  }
  return <img src="/static/CCCLogo.png" width={props.width} />
}


export default CCCLogo;
