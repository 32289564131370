import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, Autocomplete, TextField, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import SpecByteLogo from '../SpecByteLogo'
import CCCLogo from '../CCCLogo'
import CashByCreativeLogo from '../CashByCreativeLogo'
import useAuth from '../../hooks/useAuth';
import NavSection from '../NavSection'
import useSettings from '../../hooks/useSettings';

const useStyles = makeStyles((theme) => ({
  marketSelect: {
    backgroundColor: '#fff',
    borderRadius: 5
  }
}))

const sections = [
  {
    items: [
      {
        title: 'Concierge',
        path: '/concierge'
      },
      {
        title: 'My Account',
        path: '/account',
        children: [
          {
            title: 'My Specs',
            path: '/account/myspecs'
          },
          {
            title: 'Concierge',
            path: '/account/concierge'
          },
          {
            title: 'Monitor',
            path: '/account/monitor'
          },
          {
            title: 'Banners',
            path: '/account/banners'
          },
          {
            title: 'PreRolls',
            path: '/account/prerolls'
          },
          {
            title: 'Demands',
            path: '/account/demands'
          },
          {
            title: 'Favorites',
            path: '/account/favorites'
          },
          {
            title: 'Manage Users',
            path: '/account/manage-users'
          },
          {
            title: 'Account Info',
            path: '/account/accountinfo'
          },
        ]
      },
      {
        title: 'Site Admin',
        path: '/admin'
      },
      {
        title: 'Need Help?',
        path: '/help'
      },

    ]
  }
]

const loggedOutSections = [
  {
    items: [
      {
        title: 'Home',
        path: '/'
      },
      {
        title: 'Need Help?',
        path: '/help'
      },
    ]
  }
]

const FrontendSidebar = ({ onMobileClose, openMobile, markets }) => {
  const theme = useTheme()
  const { settings, setMarket } = useSettings();
  const location = useLocation();
  const classes = useStyles()
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { user, isAuthenticated, logout } = useAuth()
  const [selectedMarket, setSelectedMarket] = useState()

  const handleChangeMarket = (event, newValue) => {
    if (!newValue) return

    setSelectedMarket(newValue)
    setMarket(newValue)
  }

  useEffect(() => {
    if (selectedMarket !== undefined) { return }
    if (settings.market === undefined && user !== undefined) {
      const found = markets.find(market => market.id === Number.parseInt(user.marketId))
      if (found) {
        setSelectedMarket(found)
        setMarket(found)
      }
    } else {
      setSelectedMarket(settings.market)
    }
  }, [user, markets])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      const response = await logout()
      if (response) {
        navigate('/')
      }
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout')
    }
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: 'background.default',
          width: 256
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2
        }}
      >
        <RouterLink to="/">
          {(() => {
            switch (theme.name) {
              case 'SPECbyte':
                return <SpecByteLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CCC':
                {/* return null; */ }
                return <CCCLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CashByCreative':
                return <CashByCreativeLogo width="170px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              default:
                return null
            }
          })()}
        </RouterLink>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="body1"
            style={{ color: theme.palette.primary.main }}
          >
            {isAuthenticated ? `Hello, ${user.firstName}` : 'Welcome, please login.'}
            {/* {accounts.length > 0 && isAuthenticated ? `Hello, ${name}` : 'Welcome, please login.'} */}
          </Typography>

        </Box>
        <Box sx={{ width: '100%' }}>
          {isAuthenticated && markets.length > 1 ? (
            <Autocomplete
              getOptionLabel={(market) => `${market.name}: ${market.company?.name}`}
              options={markets}
              value={selectedMarket}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              autoComplete
              size='small'
              onChange={handleChangeMarket}
              renderInput={(params) => (
                <TextField
                  className={classes.marketSelect}
                  fullWidth
                  name="market"
                  label="Select Market"
                  variant="outlined"
                  {...params}
                  sx={{ mt: 2, backgroundColor: 'background.paper' }}
                />
              )}
            />
          ) : null}
        </Box>
        <Divider orientation='horizontal' sx={{ width: '100%', color: theme.palette.primary.main, my: 2 }} />
        {isAuthenticated ? sections.map((section, idx) => (
          <NavSection
            key={idx}
            pathname={location.pathname}
            sx={{ '& + &': { mt: 2, p: 4 } }}
            {...section}
          />
        )) : loggedOutSections.map((section, idx) => (
          <NavSection
            key={idx}
            pathname={location.pathname}
            sx={{ '& + &': { mt: 2, p: 4 } }}
            {...section}
          />
        ))}
        <Box sx={{ mt: 2 }}>
          {isAuthenticated ?
            <Button
              color="primary"
              component="a"
              onClick={handleLogout}
              size="small"
              variant="contained"
            >
              Log Out
            </Button> :
            <Button
              color="primary"
              component={RouterLink}
              to="/authentication/login"
              size="small"
              variant="contained"
            >
              Log In
            </Button>
          }
        </Box>
      </Box>
    </Drawer>
  );
};

FrontendSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default FrontendSidebar;
