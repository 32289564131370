import { experimentalStyled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    // filter: "brightness(0) invert(1)"
  }
}))


const SpecByteLogo = (props) => {
  const classes = useStyles()

  return (
    <img src="/static/SpecByteLogoV2.png" width={props.width} className={classes.logo} />
  )
};

export default SpecByteLogo;
