import { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast'
import { AppBar, Box, Divider, IconButton, Link, Toolbar } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import { useNavigate } from 'react-router';
import { useTheme } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useAPI from '../../hooks/useAPI';
import useSettings from '../../hooks/useSettings';
import { FilterBuilder } from '@loopback/filter';
import SpecByteLogo from '../SpecByteLogo'
import CCCLogo from '../CCCLogo'
import CashByCreativeLogo from '../CashByCreativeLogo'
import CreativeLoveLogo from '../CreativeLoveLogo';
import AudacyFlashLogo from '../AudacyFlashLogo';
import useMounted from '../../hooks/useMounted';
import { Market } from '../../lib/backendApi/types/market';

const AlternateNavbar = (props) => {
  const theme = useTheme()
  const mounted = useMounted()
  const { settings, setMarket } = useSettings();
  const { api } = useAPI()
  const { onSidebarMobileOpen } = props;
  const { user, isAuthenticated, isSiteAdmin, logout } = useAuth()
  const navigate = useNavigate()
  const [markets, setMarkets] = useState([])
  /** @type [number, React.Dispatch<React.SetStateAction<number>>] */
  const [selectedMarket, setSelectedMarket] = useState()

  const handleLogout = async () => {
    try {
      const response = await logout()
      if (response) {
        navigate('/')
      }
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout')
    }
  }

  const getMarkets = useCallback(async () => {
    try {
      const filter = new FilterBuilder()
      filter.include(["company", "conciergeAuthorizedMarkets"])
      filter.where({ isActive: 1 })
      filter.order("name ASC")
      const response = await api.Market.find(filter.build())
      if (response.error) {
        console.error("Unable to get markets", response)
        return
      }
      /** @type Market[] */
      const marketRes = response;

      if (mounted.current) {
        setMarkets(marketRes)
      }
    } catch (err) {
      console.error(err)
    }
  }, [mounted])

  useEffect(() => {
    getMarkets()
  }, [getMarkets])

  useEffect(() => {
    if (selectedMarket !== undefined) { return }
    if (settings.market === undefined && user !== undefined) {
      const found = markets.find(market => market.id === Number.parseInt(user.marketId))
      if (found) setSelectedMarket(found)
    } else {
      setSelectedMarket(settings.market)
    }
  }, [user, settings, markets])

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: theme.palette.navigation?.background,
        color: theme.palette.navigation?.fontColor
      }}
    >
      <Toolbar sx={{ minHeight: 62, pt: 1, pb: .5 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          {isAuthenticated ? (
            <>
              <Link
                component={RouterLink}
                to={`/concierge/12`}
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Specs
              </Link>
              <Link
                component={RouterLink}
                to={`/concierge/13`}
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Customs
              </Link>
              <Link
                component={RouterLink}
                to={`/concierge/14`}
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Digitals
              </Link>
            </>
          ) : (
            <>

            </>
          )
          }
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          {(() => {
            switch (theme.name) {
              case 'SPECbyte':
                return <SpecByteLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CCC':
                {/* return null; */ }
                return <CCCLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CashByCreative':
                return <CashByCreativeLogo width="170px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CreativeLove':
                return <CreativeLoveLogo width="110px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'AudacyFlash':
                return <AudacyFlashLogo width="90px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              default:
                return null
            }
          })()}
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          {isAuthenticated ? (
            <>
              <Link
                component={RouterLink}
                to={`/${theme.name === 'AudacyFlash' ? 'audacyflash' : 'creativelove'}/account/specs`}
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                My Account
              </Link>
              <Link
                component={RouterLink}
                to={`/${theme.name === 'AudacyFlash' ? 'audacyflash' : 'creativelove'}/help`}
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Need Help?
              </Link>
              <Link
                component="button"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
                onClick={handleLogout}
              >
                Log Out
              </Link>
            </>
          ) : (
            <>
              <Link
                component={RouterLink}
                to="/authentication/login"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Log In
              </Link>
              <Link
                component={RouterLink}
                to="/help"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Need Help?
              </Link>
            </>
          )
          }
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

AlternateNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default AlternateNavbar;
