import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import AudacySidebar from './AudacySidebar'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  accountRoot: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 64,
    // paddingLeft: 24,
    height: '100%'
  },
  accountContent: {
    flex: '1 1 auto',
    padding: theme.spacing(2)
  }
}))

const AudacyAccountLayout = () => {
  const classes = useStyles();
  const location = useLocation();
  const [hideSidebar, setHideSidebar] = useState(false)

  useEffect(() => {
    if (location.pathname.endsWith('/approved') || location.pathname.endsWith('/rejected')) {
      setHideSidebar(true);
    } else {
      setHideSidebar(false);
    }
  }, [location.pathname]);


  return (
    <Box className={classes.accountRoot} id="account-root" sx={{ paddingLeft: hideSidebar ? 0 : 4 }}>
      {hideSidebar ? null : <AudacySidebar />}
      <Box id="audacy-account-content" className={classes.accountContent}>
        <Outlet />
      </Box>
    </Box>
  )
};

export default AudacyAccountLayout; 