var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from 'axios';
import { ContainerClient } from '@azure/storage-blob';
import { v4 as uuid } from 'uuid';
class BackendAPIClient {
    constructor(endpointUrl, authToken, site) {
        this.authToken = authToken;
        this.site = site;
        this.Media = {
            path: '/media',
            getPublicUrl: (filename, expireyDays = 1, download = false) => __awaiter(this, void 0, void 0, function* () {
                const reqUrl = new URL(this.Media.path + `/${encodeURIComponent(filename)}`, Axios.defaults.baseURL);
                reqUrl.searchParams.append('download', download.toString());
                reqUrl.searchParams.append('expiryDays', expireyDays.toString());
                const result = yield Axios.get(reqUrl.toString());
                //const result = await Axios.get(this.Media.path + `/${encodeURIComponent(filename)}`)
                if (result.data.error) {
                    console.error("Error getting public url for media file", result.data.error);
                    return { publicUrl: '' };
                }
                return result.data;
            }),
            uploadFile: (file) => __awaiter(this, void 0, void 0, function* () {
                const containerUrl = yield Axios.get(this.Media.path + "/upload-url").then(res => res.data.url);
                const containerClient = new ContainerClient(containerUrl);
                //const fileName = file.name.split(".")[0] + "_" + uuid() + "." + file.name.split(".")[1]
                const fileName = `${uuid()}_${file.name}`;
                const blobClient = containerClient.getBlockBlobClient(fileName);
                const result = yield blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });
                return { filename: fileName };
            })
        };
        this.Advertisers = {
            path: '/advertisers',
            find: (filter, signal) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.Advertisers.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                return result.data;
            }),
            count: (filter) => __awaiter(this, void 0, void 0, function* () {
                if (!filter)
                    filter = {};
                const result = yield Axios.get(this.Advertisers.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                return result.data;
            }),
            create: (advertiser) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.post(this.Advertisers.path, advertiser);
                return result.data;
            })
        };
        this.Uploads = {
            path: '/uploads',
            getPublicUrl: (filename, expireyDays = 1, download = false) => __awaiter(this, void 0, void 0, function* () {
                const reqUrl = new URL(this.Uploads.path + `/public-url/${encodeURIComponent(filename)}`, Axios.defaults.baseURL);
                reqUrl.searchParams.append('download', download.toString());
                reqUrl.searchParams.append('expiryDays', expireyDays.toString());
                const result = yield Axios.get(reqUrl.toString());
                if (result.data.error) {
                    console.error("Error getting public url for uploaded file", result.data.error);
                    return { publicUrl: '' };
                }
                return result.data;
            }),
            uploadFile: (file) => __awaiter(this, void 0, void 0, function* () {
                const containerUrl = yield Axios.get(this.Uploads.path + "/upload-url").then(res => res.data.url);
                const containerClient = new ContainerClient(containerUrl);
                const fileName = `${uuid()}_${file.name}`;
                //const fileName = file.name.split(".")[0] + "_" + uuid() + "." + file.name.split(".")[1]
                const blobClient = containerClient.getBlockBlobClient(fileName);
                const result = yield blobClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type }, onProgress: (ev) => console.log(ev) });
                return { filename: fileName };
            }),
            findById: (uploadId) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.Uploads.path + `/${uploadId}`);
                return result.data;
            }),
            adminTagFind: (tagId) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.Uploads.path + `/tag/${tagId}`);
                if (result.data.error) {
                    console.error("Error getting tag", result.data.error);
                    return { result: false, message: result.data.error.message };
                }
                return result.data;
            }),
            adminBannerFind: (bannerId) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.Uploads.path + `/banner/${bannerId}`);
                if (result.data.error) {
                    console.error("Error getting banner", result.data.error);
                    return { result: false, message: result.data.error.message };
                }
                return result.data;
            }),
            adminPreRollFind: (preRollId) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.Uploads.path + `/pre-roll/${preRollId}`);
                if (result.data.error) {
                    console.error("Error getting pre-roll", result.data.error);
                    return { result: false, message: result.data.error.message };
                }
                return result.data;
            }),
            update: (uploadId, upload) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.patch(this.Uploads.path + `/${uploadId}`, upload);
                return result.data;
            }),
            delete: (upload) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.post(this.Uploads.path + `/delete`, upload);
                return result.data;
            }),
        };
        this.User = {
            path: '/users',
            me: () => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.User.path + "/me");
                if (result.data.error) {
                    return { result: true, message: result.data.error.message };
                }
                else {
                    return { result: true, user: result.data };
                }
            }),
            login: (credentials, siteOverride) => __awaiter(this, void 0, void 0, function* () {
                const rheaders = { 'Content-Type': 'application/json' };
                if (siteOverride) {
                    rheaders['site'] = siteOverride;
                }
                const result = yield Axios.post(this.User.path + "/login", credentials, { headers: rheaders });
                if (result.data.error) {
                    return { result: false, message: result.data.error.message };
                }
                else {
                    this.authToken = result.data.token;
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`;
                    const meResult = yield this.User.me();
                    if (meResult.user === undefined) {
                        return { result: false, message: 'Login failed' };
                    }
                    return { result: true, message: "success", user: meResult.user, token: this.authToken };
                }
            }),
            loginAzure: (id_token) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.User.path + "/aad-login?id_token=" + id_token);
                console.log('loginAzure backendAPI endpoint result', result);
                if (result.data.error) {
                    return { result: false, message: result.data.error.message };
                }
                else if (result.data.newUser === true) {
                    return { result: true, message: "new user", user: undefined, token: id_token, markets: result.data.markets };
                }
                else {
                    this.authToken = result.data.token;
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`;
                    const meResult = yield this.User.me();
                    if (meResult.user === undefined) {
                        return { result: false, message: 'Login failed' };
                    }
                    return { result: true, message: "success", user: meResult.user, token: this.authToken };
                }
            }),
            createAzure: (id_token, user) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.post(this.User.path + "/aad-create?id_token=" + id_token, user);
                if (result.data.error) {
                    return { result: false, message: result.data.error.message };
                }
                else {
                    this.authToken = result.data.token;
                    Axios.defaults.headers.common['Authorization'] = `Bearer ${this.authToken}`;
                    const meResult = yield this.User.me();
                    if (meResult.user === undefined) {
                        return { result: false, message: 'Login failed' };
                    }
                    return { result: true, message: "success", user: meResult.user, token: this.authToken };
                }
            }),
            registerWithKey: (object, key) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.post(this.User.path + "/register?key=" + key, object);
                return result.data;
            }),
            find: (filter) => __awaiter(this, void 0, void 0, function* () {
                if (!filter)
                    filter = {};
                const result = yield Axios.get(this.User.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                return result.data;
            }),
            roleChange: (userId, roleId, addRemove) => __awaiter(this, void 0, void 0, function* () {
                let result;
                if (addRemove) {
                    result = yield Axios.post(this.User.path + `/${userId}/roles?roleId=${roleId}`);
                }
                else {
                    result = yield Axios.delete(this.User.path + `/${userId}/roles?roleId=${roleId}`);
                }
                return result.data;
            }),
            managerList: (marketId, page, rows, count, search, orderBy, order, signal) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.User.path + "/roles?page=" + page + "&rows=" + rows + "&count=" + count + "&market=" + marketId + "&orderBy=" + orderBy + "&order=" + order + (search ? "&search=" + search : ""), { signal });
                if (count)
                    return result.data[0].Total;
                return result.data;
            }),
            findById: (id, filter) => __awaiter(this, void 0, void 0, function* () {
                if (!filter)
                    filter = {};
                const result = yield Axios.get(this.User.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                return result.data;
            }),
            create: (object) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.post(this.User.path, object);
                return result.data;
            }),
            update: (id, object) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.patch(this.User.path + `/${id}`, object);
                return result.data;
            }),
            delete: (id) => __awaiter(this, void 0, void 0, function* () {
                throw new Error('Function not implemented.');
            }),
            count: (filter) => __awaiter(this, void 0, void 0, function* () {
                if (!filter)
                    filter = {};
                const result = yield Axios.get(this.User.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                return result.data;
            }),
            startRegister: (accessKey) => __awaiter(this, void 0, void 0, function* () {
                const result = yield Axios.get(this.User.path + `/startregister?accessKey=${accessKey}`);
                return result.data;
            })
        };
        this.Campaign = {
            path: '/campaign',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            countByCategory(categoryId, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/countByCategory/${categoryId}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            }
        };
        this.CampaignFavorite = {
            path: '/campaignFavorite',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            }
        };
        this.Category = {
            path: '/category',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            companyCategoryChange(companyId, categoryId, addRemove) {
                return __awaiter(this, void 0, void 0, function* () {
                    let result;
                    if (addRemove) {
                        result = yield Axios.post(this.path + `/companies/${companyId}?categoryId=${categoryId}`);
                    }
                    else {
                        result = yield Axios.delete(this.path + `/companies/${companyId}?categoryId=${categoryId}`);
                    }
                    return result.data;
                });
            },
            findByCategoryId(categoryId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/${categoryId}/companies`);
                    return result.data;
                });
            },
            // async companyCategoryChange(companyIds: Array<number>, categoryId: ID, addRemove: boolean): Promise<void | ErrorResponse> {
            //     let result
            //     if (addRemove) {
            //         result = await Axios.post(this.path + `/companies/${categoryId}?companyIds=${encodeURIComponent(JSON.stringify(companyIds))}`)
            //     } else {
            //         result = await Axios.delete(this.path + `/companies/${categoryId}?companyIds=${encodeURIComponent(JSON.stringify(companyIds))}`)
            //     }
            //     return result.data
            // }
        };
        this.ConciergeService = {
            path: '/conciergeService',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const reuslt = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return reuslt.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            }
        };
        this.Concierge = {
            path: '/concierge',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            revise(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            newAssets(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/newassets/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            upload(id, files) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/concierge/${id}`, files);
                    return result.data;
                });
            },
            deleteUpload(id, upload) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/concierge/delete/${id}`, upload);
                    return result.data;
                });
            },
            deliver(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/concierge/deliver`, data);
                    return result.data;
                });
            },
            generate(prompts) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + `/generate`, prompts);
                    return result.data;
                });
            }
        };
        this.ConciergeSold = {
            path: '/conciergeSold',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            }
        };
        this.Tag = {
            path: '/tag',
            find(filter = {}, marketId, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}${marketId ? `&marketId=${marketId}` : ''}`);
                    return result.data;
                });
            },
            adminFind(filter = {}, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/admin?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    //@ts-ignore
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter, marketId) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}&marketId=${marketId}`);
                    return result.data;
                });
            },
            reassign(id, producerEmail) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + `/${id}/reassign?email=${producerEmail}`);
                    return result.data;
                });
            },
            upload(id, files) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/tag/${id}`, files);
                    return result.data;
                });
            },
            deleteUpload(id, upload) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/tag/delete/${id}`, upload);
                    return result.data;
                });
            },
            deliver(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/deliver/tag`, data);
                    return result.data;
                });
            },
        };
        this.CustomTagSold = {
            path: '/customTagSold',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            }
        };
        this.CustomBanner = {
            path: '/customBanner',
            find(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal: signal });
                    return result.data;
                });
            },
            adminFind(filter = {}, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/admin?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal: signal });
                    return result.data;
                });
            },
            create(object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object, { signal: signal });
                    return result.data;
                });
            },
            update(id, object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object, { signal: signal });
                    return result.data;
                });
            },
            revise(id, object, notes, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}/revise?notes=${notes}`, object, { signal: signal });
                    return result.data;
                });
            },
            delete(id, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal: signal });
                    return result.data;
                });
            },
            upload(id, files) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/banner/${id}`, files);
                    return result.data;
                });
            },
            deliver(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/banner/deliver`, data);
                    return result.data;
                });
            },
        };
        this.CustomBannerSold = {
            path: '/customBannerSold',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            }
        };
        this.CustomPreRoll = {
            path: '/customPreRoll',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            adminFind(filter = {}, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/admin?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            upload(id, files) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/preroll/${id}`, files);
                    return result.data;
                });
            },
            deliver(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(`uploads/preroll/deliver`, data);
                    return result.data;
                });
            },
        };
        this.CustomPreRollSold = {
            path: '/customPreRollSold',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + `/${id}`, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.delete(this.path + `/${id}`);
                    return result.data;
                });
            }
        };
        this.CustomSpotRequest = {
            path: '/customSpotRequest',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findByMarket(marketId, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `ByMarket/${marketId}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            }
        };
        this.OnDemand = {
            path: '/onDemand',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            }
        };
        this.Market = {
            path: '/market',
            find(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal });
                    return result.data;
                });
            },
            findById(id, filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal });
                    return result.data;
                });
            },
            create(object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object, { signal });
                    return result.data;
                });
            },
            update(id, object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal });
                    return result.data;
                });
            }
        };
        this.Producer = {
            path: '/producers',
            find(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`, { signal });
                    return result.data;
                });
            },
            findById(id, filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            create(object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            update(id, object, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            }
        };
        this.Company = {
            path: '/company',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            getRegistrationKey(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/${id}/access-key`);
                    return result.data;
                });
            }
        };
        this.TrackedItem = {
            path: '/trackedItem',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, object);
                    return result.data;
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.patch(this.path + '/' + id, object);
                    return result.data;
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            count(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/count?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
        };
        this.CustomExecutionElement = {
            path: '/customExecutionElement',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            }
        };
        this.ConciergeAuthorizedCompany = {
            path: '/conciergeAuthorizedCompany',
            find(filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            findById(id, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            create(object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            update(id, object) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            delete(id) {
                return __awaiter(this, void 0, void 0, function* () {
                    throw new Error('Function not implemented.');
                });
            },
            findByCompanyId(companyId, filter) {
                return __awaiter(this, void 0, void 0, function* () {
                    if (!filter)
                        filter = {};
                    const result = yield Axios.get(this.path + `/${companyId}?filter=${encodeURIComponent(JSON.stringify(filter))}`);
                    return result.data;
                });
            }
        };
        this.Account = {
            path: '/account',
            sendEmailConfirmation(userId) {
                return __awaiter(this, void 0, void 0, function* () {
                    yield Axios.post(this.path + `/sendEmailConfirmation`, { userId: userId });
                });
            },
            confirmEmail(id, token) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/confirmEmail?userId=${id}&token=${token}`);
                    return result.data;
                });
            },
            sendPasswordReset(email, whiteLabel) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + `/sendPasswordReset`, { email: email, whiteLabel: whiteLabel });
                    return result.data;
                });
            },
            resetPassword(userId, token, password, passwordConfirm) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + `/resetPassword`, { userId: userId, token: token, password: password, passwordConfirm: passwordConfirm });
                    return result.data;
                });
            }
        };
        this.SiteAnnouncement = {
            path: '/site-announcement',
            get(signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/active", { signal });
                    return result.data;
                });
            },
            getLast(signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/last", { signal });
                    return result.data;
                });
            },
            save(announcement, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path, announcement, { signal });
                    return result.data;
                });
            }
        };
        this.Reports = {
            path: '/reports',
            federationRevenueYTD(signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/federation-revenue-ytd", { signal });
                    return result.data;
                });
            },
            quarterlySales(marketId, dateStart, dateEnd, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/quarterly-sales?market=" + marketId + (dateStart ? `&dateStart=${dateStart}` : "") + (dateEnd ? `&dateEnd=${dateEnd}` : ""), { signal });
                    return result.data;
                });
            },
            salesByUser(marketId, startDate, endDate, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path
                        + "/sales-by-user?market=" + marketId
                        + "&startDate=" + `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`
                        + "&endDate=" + `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`, { signal });
                    return result.data;
                });
            },
            revenue(companyId, dateStart, dateEnd, orderBy, order, page, rows, count, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/revenue?page=" + page + "&rows=" + rows + "&count=" + count + "&" + (companyId ? `federation=${companyId}` + (dateStart || dateEnd || orderBy || order ? "&" : "") : "") + (dateStart ? `startDate=${dateStart}` + (dateEnd || orderBy || order ? "&" : "") : "") + (dateEnd ? `endDate=${dateEnd}` + (orderBy || order ? "&" : "") : "") + (orderBy ? `orderBy=${orderBy}` + (order ? "&" : "") : "") + (order ? `order=${order}` : ""), { signal });
                    return result.data;
                });
            },
            revenueSums(companyId, dateStart, dateEnd, count, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/revenue-sums?count=" + count + "&" + (companyId ? `federation=${companyId}` + (dateStart || dateEnd ? "&" : "") : "") + (dateStart ? `startDate=${dateStart}` + (dateEnd ? "&" : "") : "") + (dateEnd ? `endDate=${dateEnd}` : ""), { signal });
                    return result.data;
                });
            },
            adminTagList(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/admin/tag-list?filter=" + encodeURIComponent(JSON.stringify(filter)), { signal });
                    return result.data;
                });
            },
            adminTagListCount(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/admin/tag-list/count?filter=" + encodeURIComponent(JSON.stringify(filter)), { signal });
                    return result.data;
                });
            },
            adminBannerList(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/admin/banner-list?filter=" + encodeURIComponent(JSON.stringify(filter)), { signal });
                    return result.data;
                });
            },
            adminBannerListCount(filter, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/admin/banner-list/count?filter=" + encodeURIComponent(JSON.stringify(filter)), { signal });
                    return result.data;
                });
            },
            getAdminDailySalesOverview() {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + "/admin-daily-sales-overview");
                    return result.data;
                });
            },
            getCompanyDailySalesOverview(companyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/company-daily-sales-overview/${companyId}`);
                    return result.data;
                });
            },
            getCompanyDailyMarketSales(companyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/company-daily-market-sales/${companyId}`);
                    return result.data;
                });
            },
            getCompanyTopMonthlyMarketSales(companyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path + `/company-top-monthly-market-sales/${companyId}`);
                    return result.data;
                });
            },
            getCustomSalesReport(salesReports, startDate, endDate, companyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const dateStart = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
                    const dateEnd = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${(endDate.getDate()).toString().padStart(2, '0')}`;
                    const result = yield Axios.post(this.path + `/custom-sales-report`, { salesReports, dateStart, dateEnd, companyId });
                    return result.data;
                });
            },
            getRequestCounts(companyId, startDate, endDate) {
                return __awaiter(this, void 0, void 0, function* () {
                    const dateStart = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
                    const dateEnd = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${(endDate.getDate()).toString().padStart(2, '0')}`;
                    const result = yield Axios.post(this.path + `/request-counts`, { companyId, dateStart, dateEnd });
                    return result.data;
                });
            },
            getRequestReportTableCounts(companyId, startDate, endDate, order) {
                return __awaiter(this, void 0, void 0, function* () {
                    const dateStart = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
                    const dateEnd = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${(endDate.getDate()).toString().padStart(2, '0')}`;
                    const result = yield Axios.post(this.path + `/request-report-table-counts`, { companyId, dateStart, dateEnd, order });
                    return result.data;
                });
            },
            getCustomRequestsReport(requestsReports, startDate, endDate, companyId) {
                return __awaiter(this, void 0, void 0, function* () {
                    const dateStart = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
                    const dateEnd = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${(endDate.getDate()).toString().padStart(2, '0')}`;
                    const result = yield Axios.post(this.path + `/custom-requests-report`, { requestsReports, dateStart, dateEnd, companyId });
                    return result.data;
                });
            },
            getCompanyUserRequestCounts(companyId, startDate, endDate, order, page, rows) {
                return __awaiter(this, void 0, void 0, function* () {
                    const dateStart = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
                    const dateEnd = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${(endDate.getDate()).toString().padStart(2, '0')}`;
                    const result = yield Axios.post(this.path + `/company-user-request-counts`, { companyId, dateStart, dateEnd, order, page, rows });
                    return result.data;
                });
            },
        };
        this.OpenAi = {
            path: '/openai',
            generateScript(prompts) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + `/generate`, prompts);
                    return result.data;
                });
            },
        };
        this.Role = {
            path: '/role',
            find(signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.get(this.path, { signal });
                    return result.data;
                });
            },
        };
        this.AdminUtils = {
            path: '/admin-utils',
            syncAddUserMarkets() {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/update-user-additional-markets");
                    return result.data;
                });
            },
            changeAdditionalUserMarkets(marketsToAdd, marketsToRemove, userEmail) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/change-user-additional-markets", { marketsToAdd, marketsToRemove, userEmail });
                    return result.data;
                });
            },
            markTagCompleted(tagAssignmentId, reason, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/mark-tag-completed", { tagAssignmentId: tagAssignmentId, reason: reason }, { signal });
                    return result.data;
                });
            },
            resendConciergeToVPro(conciergeId, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/concierge/vpro-resend", { conciergeId: conciergeId }, { signal });
                    return result.data;
                });
            },
            resendTagToVpro(tagId, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/tag/vpro-resend", { tagId: tagId }, { signal });
                    return result.data;
                });
            },
            resendBannerToVpro(bannerId, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/banner/vpro-resend", { bannerId: bannerId }, { signal });
                    return result.data;
                });
            },
            resendPreRollToVpro(preRollId, signal) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/pre-roll/vpro-resend", { preRollId: preRollId }, { signal });
                    return result.data;
                });
            },
        };
        this.Enco = {
            path: '/enco',
            createPreviewAudio(data) {
                return __awaiter(this, void 0, void 0, function* () {
                    const result = yield Axios.post(this.path + "/preview", data);
                    return result.data;
                });
            },
        };
        Axios.defaults.baseURL = endpointUrl;
        Axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        Axios.defaults.headers.common['Content-Type'] = 'application/json';
        Axios.defaults.headers.common['Site'] = site;
        if (localStorage.getItem('site') !== null) {
            Axios.defaults.headers.common['Site'] = localStorage.getItem('site');
        }
    }
    ChangeSite(site) {
        Axios.defaults.headers.common['Site'] = site;
        localStorage.setItem('site', site);
    }
}
export default BackendAPIClient;
