import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast'
import { AppBar, Autocomplete, Box, Button, Divider, IconButton, Link, TextField, Toolbar, Typography, makeStyles } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import { useNavigate } from 'react-router';
import { useTheme } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
import SpecByteLogo from '../SpecByteLogo'
import CCCLogo from '../CCCLogo'
import CashByCreativeLogo from '../CashByCreativeLogo'
import CreativeLoveLogo from '../CreativeLoveLogo';
import AudacyFlashLogo from '../AudacyFlashLogo';
import XIcon from '../../icons/X';

const useStyles = makeStyles((theme) => ({
  marketSelect: {
    backgroundColor: '#fff',
    borderRadius: 5,
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
  }
}))

const FrontendNavbar = ({ onSidebarMobileOpen, markets, announcement, hideAnnouncement }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { settings, setMarket } = useSettings();
  const { user, isAuthenticated, isSiteAdmin, logout } = useAuth()
  const navigate = useNavigate()
  /** @type [number, React.Dispatch<React.SetStateAction<number>>] */
  const [selectedMarket, setSelectedMarket] = useState()

  const handleLogout = async () => {
    try {
      const response = await logout()
      if (response) {
        navigate('/')
      }
    } catch (err) {
      console.error(err)
      toast.error('Unable to logout')
    }
  }

  const handleChangeMarket = (event, newValue) => {
    if (!newValue) return

    setSelectedMarket(newValue)
    setMarket(newValue)
  }

  useEffect(() => {
    if (selectedMarket !== undefined) { return }
    if (settings.market === undefined && user !== undefined) {
      const found = markets.find(market => market.id === Number.parseInt(user.marketId))
      if (found !== undefined) {
        setSelectedMarket(found)
        setMarket(found)
      }
    } else {
      setSelectedMarket(settings.market)
    }
  }, [user, markets, settings.market])

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: theme.palette.navigation?.background,
        color: theme.palette.navigation?.fontColor
      }}
    >
      <Toolbar sx={{ minHeight: 62, pt: 1, pb: .5 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          {(() => {
            switch (theme.name) {
              case 'SPECbyte':
                return <SpecByteLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CCC':
                {/* return null; */ }
                return <CCCLogo width="180px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CashByCreative':
                return <CashByCreativeLogo width="170px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'CreativeLove':
                return <CreativeLoveLogo width="110px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              case 'AudacyFlash':
                return <AudacyFlashLogo width="90px" sx={{ display: { md: 'inline', xs: 'none' }, }} />;
              default:
                return null
            }
          })()}
        </RouterLink>
        <Box sx={{ flexGrow: 1 }}>
          {isAuthenticated && markets.length > 1 ? (
            <Autocomplete
              getOptionLabel={(market) => `${market.name}: ${market.company?.name}`}
              options={markets}
              value={selectedMarket || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              autoComplete
              size='small'
              onChange={handleChangeMarket}
              renderInput={(params) => (
                <TextField
                  className={classes.marketSelect}
                  fullWidth
                  name="market"
                  placeholder='Select Market'
                  variant="outlined"
                  {...params}
                  sx={{ ml: 4, backgroundColor: 'background.paper' }}
                />
              )}
            />
          ) : null}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
          {isAuthenticated
            ? <>
              {theme.name !== 'CCC' && <Link
                component={RouterLink}
                to="/concierge"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Concierge
              </Link>}
              <Link
                component={RouterLink}
                to="/account/myspecs"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                My Account
              </Link>
              {isSiteAdmin && <Link
                component={RouterLink}
                to="/admin"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Site Admin
              </Link>}
              <Link
                component={RouterLink}
                to="/help"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Need Help?
              </Link>
            </>
            : <>
              <Link
                component={RouterLink}
                to="/"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Home
              </Link>
              <Link
                component={RouterLink}
                to="/help"
                underline="none"
                variant="body2"
                sx={{ mx: 2, color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
              >
                Need Help?
              </Link>
            </>
          }
          <Divider orientation="vertical" sx={{ height: 32, mx: 2 }} />
          <Typography
            variant="body2"
            style={{ color: theme.palette.navigation?.fontColor, fontWeight: 600, fontSize: 18 }}
          >
            {isAuthenticated ? `Hello, ${user.firstName}` : 'Welcome, please login.'}
          </Typography>
          {isAuthenticated
            ? <Button
              component="a"
              onClick={handleLogout}
              size="medium"
              variant="contained"
              sx={{ ml: 2, backgroundColor: theme.palette.primary.main, fontWeight: 600, fontSize: 18 }}
            >
              Log Out
            </Button>
            : <Button
              component={RouterLink}
              size="medium"
              to="/authentication/login"
              variant="contained"
              sx={{ ml: 2, backgroundColor: theme.palette.primary.main, fontWeight: 600, fontSize: 18 }}
            >
              Log In
            </Button>
          }
        </Box>
      </Toolbar>
      <Divider />
      {
        announcement && theme.name !== 'Admin'
          ? <Box sx={{ backgroundColor: announcement.backgroundColor, pb: 1, pt: 0.5, position: 'relative' }}>
            <Box>
              <Typography variant="h6" color={announcement.textColor} align="center">
                {announcement.title}
              </Typography>
              <Typography variant="body2" color={announcement.textColor} align="center">
                <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
              </Typography>
            </Box>
            <IconButton onClick={hideAnnouncement} sx={{ position: 'absolute', height: '100%', mr: 1, top: 0, right: 0 }}>
              <XIcon sx={{ color: announcement.textColor }} />
            </IconButton>
          </Box>
          : null
      }
    </AppBar>
  );
};

FrontendNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default FrontendNavbar;
