import { experimentalStyled } from '@material-ui/core/styles';


const AudacyFlashLogo = (props) => {
  if (props.variant == 'dark') {
    return <img src="/static/AudacyFlashLogo.png" width={props.width} />
  }
  return <img src="/static/AudacyFlashLogo.png" width={props.width} />
};

export default AudacyFlashLogo;
