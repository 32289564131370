import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import "@fontsource/montserrat";
import DynamicFavicon from './components/DynamicFavicon';
import ImpersonationOverlay from './components/ImpersonationOverlay';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: window.location.pathname.includes('/admin') ? 'ADMIN' : settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <DynamicFavicon theme={theme} />
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {window.location.hostname === 'localhost' && <SettingsDrawer />}
        {auth.isInitialized ? content : <SplashScreen />}
        {localStorage.getItem('impersonating') === "true" && <ImpersonationOverlay />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
