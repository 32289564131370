import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import FrontendFooter from './FrontendFooter'
import FrontendNavbar from './FrontendNavbar'
import FrontendSidebar from './FrontendSidebar'
import AlternateNavbar from './AlternateNavbar';
import useAPI from '../../hooks/useAPI'
import useAuth from '../../hooks/useAuth'
import { Market } from '../../lib/backendApi/types/market';
import toast from 'react-hot-toast';

const FrontendLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  minHeight: '100vh',
  paddingTop: 64,
  paddingBottom: 64,
  marginBottom: -64,
}));

const FrontendLayout = ({ children }) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const theme = useTheme()
  const [markets, setMarkets] = useState([])
  const [announcement, setAnnouncement] = useState()
  const { api } = useAPI()
  const { isAuthenticated, isMarketWideUser, user } = useAuth()

  /**
   * 
   * @param {AbortSignal} abort 
   * @returns 
   */
  const getMarkets = async (abort) => {
    try {
      let filter = {
        where: {
          and: [
            { isActive: true },
            { company: { exists: true } }
          ]
        },
        include: [
          {
            relation: "company",
            scope: {
              where: { isActive: true }
            }
          },
          { relation: "conciergeAuthorizedMarkets" }
        ],
        order: "name ASC",
        limit: 250
      }
      const response = await api.Market.find(filter, abort)
      if (response.hasOwnProperty("error")) {
        console.error("Unable to get markets", response)
        toast.error("Error getting market data");
        return
      }
      /** @type Market[] */
      const marketRes = response.filter(market => market.company);
      if (marketRes.hasOwnProperty("error")) {
        console.error("Unable to get markets", marketRes)
        toast.error("Error getting market data");
        return
      }

      if (isMarketWideUser && user.additionalMarkets) {
        user.additionalMarkets.forEach(additionalMarket => {
          if (!marketRes.find(market => market.id === additionalMarket.id)) marketRes.push(additionalMarket)
        })
      }

      setMarkets(marketRes)
    } catch (err) {
      if (abort.aborted) return
      console.error(err)
    }
  }

  const getAnnouncement = async (abort) => {
    if (sessionStorage.getItem('hideAnnouncement')) return
    try {
      const data = await api.SiteAnnouncement.get(abort)
      if (data.error) {
        console.error("Unable to get announcement", data)
        return
      }
      if (Object.keys(data).length === 0) return
      setAnnouncement(data)
    } catch (err) {
      if (abort.aborted) return
      console.error(err)
    }
  }

  const hideAnnouncement = () => {
    setAnnouncement(null)
    sessionStorage.setItem("hideAnnouncement", "true")
  }

  useEffect(() => {
    if (!isAuthenticated) return

    const controller = new AbortController()
    getMarkets(controller.signal)
    getAnnouncement(controller.signal)
    return () => controller.abort()

  }, [isAuthenticated])

  return (
    <>
      <FrontendLayoutRoot>
        {theme.name != "AudacyFlash" && theme.name != "CreativeLove"
          ? <FrontendNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} markets={markets} announcement={announcement} hideAnnouncement={hideAnnouncement} />
          : <AlternateNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} markets={markets} announcement={announcement} hideAnnouncement={hideAnnouncement} />
        }

        <FrontendSidebar onMobileClose={() => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} markets={markets} announcement={announcement} hideAnnouncement={hideAnnouncement} />

        {children || <Outlet />}

      </FrontendLayoutRoot>
      <FrontendFooter />
    </>
  );
};

FrontendLayout.propTypes = {
  children: PropTypes.node
};

export default FrontendLayout;
