import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import AccountSidebar from './AccountSidebar';

const useStyles = makeStyles(theme => ({
  accountRoot: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(4),
  },
  accountContent: {
    flex: '1 1 auto',
    padding: theme.spacing(2),
  }
}))

const AccountLayout = () => {
  const styles = useStyles();

  return (
    <Box className={styles.accountRoot} id="account-root">
      <AccountSidebar />
      <Box id="account-content" className={styles.accountContent}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AccountLayout;
